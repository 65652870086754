console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
	}

	$screenOverlay.on('click', closeMenu);

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();




	var toggle = document.getElementById('theme-toggle'),
		root = document.getElementsByTagName('html')[0];
	toggle.addEventListener('change', function () {
		
		if (sessionStorage.getItem('theme') !== 'light') {
			sessionStorage.setItem('theme', 'light');
			root.classList.remove('dark');
			root.classList.add('light');
		} else {
			sessionStorage.setItem('theme', 'dark');
			root.classList.remove('light');
			root.classList.add('dark');
		}

		setTimeout( () => ScrollTrigger.refresh(), 500 );

	});


});

window.onbeforeunload = function() {
	//localStorage.removeItem('theme');
	//return '';
  };



jQuery(function($) {

    let active;

    const $team = $('.team').on('click', '[data-toggle=collapse]', function() {

        const member = this.closest('.team-member');

        let scrollTop = 0;

        if(active) {

            scrollTop -= active.getBoundingClientRect().top < member.getBoundingClientRect().top ?  $('.team-member-collapse', active).outerHeight() : 0;

            active.classList.remove('active');
            active.style.setProperty('--collapse-height', 0);

        }

        if(active === member) {

            active = null;

            $team.removeClass('expanded');

        } else {

            active = member;

            scrollTop += $('.team-member-collapse', member).offset().top - 175;

            $team.addClass('expanded');

            setCollapseSize();

            setTimeout( () => $(document.scrollingElement).animate({
                scrollTop : scrollTop
            }), 200 );

        }

    });

    function setCollapseSize() {

        active?.classList.add('active');
        active?.style.setProperty('--collapse-height', active.querySelector('.team-member-collapse').scrollHeight + 'px');

    }

    $(window).resize(setCollapseSize);

});

jQuery(function ($) {

	$("[data-viplayer-id]").each(function () {

		const player = new Vimeo.Player(this, {
			playsinline	: true,
			background	: true,
			autoplay	: true,
			muted		: true,
			loop		: true,
			controls	: false,
			byline		: false,
			title		: false,
			id			: this.dataset.viplayerId
		});

		$(`[data-mute="${this.dataset.viplayerId}"]`).on( 'click', () => void player.getMuted().then( isMuted => void player.setMuted(!isMuted) ) );

	});


	$("video").prop('muted', true);

	$(".toggle-mute.mp4").click( function (){
		if( $("video").prop('muted') ) {
			$("video").prop('muted', false);
			$(this).text("Sound off");
		} else {
		$("video").prop('muted', true);
		$(this).text("Sound on");
		}
	});

});



